<!-- src/components/HomePage.vue -->
<template>
  <div>
    <nav class="nav-side d-flex flex-column justify-content-between align-items-center">
      <div class="nav-name-wrapper vertical-text">
        <a href="https://www.lamaisonannecy.com" class="menu-name">
          <div class="neonText">La Maison</div>
        </a>
      </div>
      <div class="nav-some_wrapper">
        <a href="https://www.instagram.com/lamaison_annecy/" target="_blank" class="social-link">
          <img src="@/assets/instagram.svg" alt="Instagram logo">
        </a>
      </div>
    </nav>
    <main class="content flex-grow-1 d-flex flex-column" id="top">
      <div class="main-content flex-grow-1 d-flex linebottom" id="menus">
        <div class="content-text d-flex align-items-left justify-content-left" style="flex: 2;">
          <div class="main-content flex-grow-1 d-flex contact-section">
        <div class="content-text d-flex align-items-left justify-content-left">
            <h1 class="display-1">Contact</h1>
            <p><strong>La Maison</strong><br>2A Quai Perrière<br> 74000 Annecy</p>
            <p><strong>Téléphone :</strong> <a href="tel:+33450026399">+33 (0)4 50 02 63 99</a></p>
            <p><strong>Principal :</strong> <a href="mailto:contact@lamaisonannecy.com">contact@lamaisonannecy.com</a></p>
            <p><strong>Réservations :</strong> <a href="mailto:reservations@lamaisonannecy.com">reservations@lamaisonannecy.com</a></p>
            <p><strong>Factures :</strong> <a href="mailto:factures@lamaisonannecy.com">factures@lamaisonannecy.com</a></p>
            <p><strong>Cuisine :</strong> <a href="mailto:cuisine@lamaisonannecy.com">cuisine@lamaisonannecy.com</a></p>
            <p><strong>Bar :</strong> <a href="mailto:bar@lamaisonannecy.com">bar@lamaisonannecy.com</a></p>
            <p><strong>Salle :</strong> <a href="mailto:salle@lamaisonannecy.com">salle@lamaisonannecy.com</a></p>
            <p><strong>Devis :</strong> <a href="mailto:devis@lamaisonannecy.com">devis@lamaisonannecy.com</a></p>
            <p><strong>Ressources humaines :</strong> <a href="mailto:rh@lamaisonannecy.com">rh@lamaisonannecy.com</a></p>
            <p><strong>Candidatures :</strong> <a href="mailto:recrutement@lamaisonannecy.com">recrutement@lamaisonannecy.com</a></p>
            <p><strong>Partenariats :</strong> <a href="mailto:partenaire@lamaisonannecy.com">partenaire@lamaisonannecy.com</a></p>
            <p><strong>Événementiel :</strong> <a href="mailto:evenementiel@lamaisonannecy.com">evenementiel@lamaisonannecy.com</a></p>
            <p><strong>Communication et médias :</strong> <a href="mailto:communication@lamaisonannecy.com">communication@lamaisonannecy.com</a></p>
        </div>
      </div>
        </div>
      </div>
      <div class="main-content flex-grow-1 d-flex linebottom">
        <div class="content-text d-flex align-items-center justify-content-center" style="flex: 1; overflow:hidden;">
          <div class="scroll-text">
            Découvrez l'expérience culinaire festive au cœur d'Annecy
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>

export default {
  name: 'HomePage',
  components: {
  }
};
</script>

<style scoped>
@font-face {
  font-family: 'Adventures Unlimited';
  src: local('Adventures Unlimited Script Bold'), local('Adventures-Unlimited-Script-Bold'),
      url('../assets/fonts/AdventuresUnlimited-ScriptBold.woff2') format('woff2'),
      url('../assets/fonts/AdventuresUnlimited-ScriptBold.woff') format('woff'),
      url('../assets/fonts/AdventuresUnlimited-ScriptBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0..900;1..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gotham:wght@100..900&display=swap');

body, html, #app {
  height: 100%;
  margin: 0;
  background-color: #fbecd2; /* Light background color */
  color: #1a1a1a; /* Dark text color */
  overflow-x: hidden; /* Prevent horizontal scroll */
  font-family: 'Gotham', sans-serif;
}

a{
  color: black;
}

a:hover{
  text-decoration: underline !important;
}

h1 {
  font-family: 'Fjalla One', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

h2 {
  font-family: 'Fjalla One', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
}

.neonText {
  font-family: 'Adventures Unlimited', cursive;
}

.nav-side {
  border-right: 1px solid #1a1a1a; /* Dark border color */
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 5.714rem;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
}

.vertical-text {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
  font-size: 3em;
}

.content {
  margin-left: 5.714rem;
  padding: 0;
  overflow-y: auto;
  min-height: 100vh; /* Ensure content takes full height */
  display: flex;
  flex-direction: column;
}

.nav-top {
  width: 100%;
  height: 4rem;
  border-bottom: 1px solid #1a1a1a; /* Dark border color */
  display: flex;
  align-items: center;
  padding: 0; /* Remove padding */
  font-weight: 400;
}

.nav-item {
  flex: 1;
  text-align: center;
}

.nav-item:hover {
  background-color: #1a1a1a; /* Dark background color */
  color: #fbecd2; /* Light text color */
}

.nav-link {
  font-family: 'Fjalla One', sans-serif;
  color: #1a1a1a; /* Dark text color */
  text-align: center;
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  font-size: 1rem;
  width: 100%;
  height: 100%;
  text-transform: uppercase;
}

.separator {
  width: 1px;
  height: 100%;
  background-color: #1a1a1a; /* Dark separator color */
}

.main-content {
  display: flex;
  flex-grow: 1;
}

.content-text {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content-image {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lineright {
  border-right: 1px solid #1a1a1a; /* Dark border color */
}

.lineleft {
  border-left: 1px solid #1a1a1a; /* Dark border color */
}

.linetop {
  border-top: 1px solid #1a1a1a; /* Dark border color */
}

.linebottom {
  border-bottom: 1px solid #1a1a1a; /* Dark border color */
}

.content-image img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure image covers the container */
}

.footer {
  border-top: 1px solid #1a1a1a; /* Dark border color */
  color: #1a1a1a; /* Dark text color */
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  padding-bottom: 5em !important;
}

.footer p {
  margin: 0.5rem 0;
}

a {
  text-decoration: none !important;
}

.neonText {
  color: #1a1a1a; /* Dark text color */
  font-family: 'Adventures Unlimited', cursive;
  cursor: pointer;
  width: fit-content;
}

.neonText:hover {
  color: #fff;
  text-shadow:
    0 0 2px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px #fff,
    0 0 82px #fff,
    0 0 92px #fff,
    0 0 102px #fff,
    0 0 151px #fff;
}

.bigneon{
  font-size: 10rem;
}

.scroll-text {
  text-transform: uppercase;
  display: inline-block;
  white-space: nowrap;
  animation: scroll 20s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.subtitle {
  font-family: 'Merriweather Sans', sans-serif;
  font-weight: 300;
  font-style: italic;
}

/* Responsive Styles */
@media (max-width: 1210px) {

  .vertical-text {
    font-size: 2.5em;
  }

  .bigneon{
    font-size: 5em;
  }

  .nav-top {
    height: 3rem;
  }

  h1{
    font-size: 3em;
  }

  h2{
    font-size: 1.6em;
  }

  .nav-link {
    font-size: 0.9rem;
  }

}

@media (max-width: 610px) {
  .nav-side {
    width:3em;
  }

  .nav-top {
    flex-direction: column;
    height: auto;
  }

  .nav-link {
    padding: 0.5rem 0;
  }

  .separator {
    display: none;
  }

  .main-content {
    flex-direction: column;
  }

  .content-text {
    padding: rem;
  }

  .content{
    margin-left: 1em;
  }

  .bigneon {
    font-size: 5rem;
  }

  .lineleft{
    border: none;
  }
  .lineright{
    border: none;
  }
  .linetop{
    border: none;
  }

  .footer {
    flex-direction: column;
    text-align: center;
  }
  .motif{
    display:none;
  }
  .notebas {
    margin-bottom: 5rem;
  }
}
</style>
