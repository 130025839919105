<template>
  <div class="menus-page">
    <h1 class="display-1">Menus</h1>
    <p>Découvrez l'expérience culinaire festive au cœur d'Annecy. Notre menu propose une variété de plats exquis, préparés avec des ingrédients frais et de qualité, pour vous offrir une expérience culinaire inoubliable.</p>
    <div class="menu-buttons">
      <div class="menu-buttons-row">
        <a href="https://api.menutech.com/public/menus/H2IT43Da/?base_lang=fr&response=html" target="_blank" class="menu-button">Midi</a>
        <a href="https://api.menutech.com/public/menus/dsl7bhLQ/?base_lang=fr&response=html" target="_blank" class="menu-button">Soir</a>
        <a href="https://api.menutech.com/public/menus/5DinYBkH/?base_lang=fr&response=html" target="_blank" class="menu-button">Boissons</a>
        <a href="https://api.menutech.com/public/menus/YJPHaQym/?base_lang=fr&response=html" target="_blank" class="menu-button">Vins</a>
      </div>
      <div class="menu-buttons-row">
        <a href="https://api.menutech.com/public/menus/H2IT43Da/?base_lang=en&response=html" target="_blank" class="menu-button en-button">Lunch</a>
        <a href="https://api.menutech.com/public/menus/dsl7bhLQ/?base_lang=en&response=html" target="_blank" class="menu-button en-button">Dinner</a>
        <a href="https://api.menutech.com/public/menus/5DinYBkH/?base_lang=en&response=html" target="_blank" class="menu-button en-button">Drinks</a>
        <a href="https://api.menutech.com/public/menus/YJPHaQym/?base_lang=en&response=html" target="_blank" class="menu-button en-button">Wines</a>
      </div>
      <div class="menu-buttons-row">
        <a href="https://api.menutech.com/public/menus/H2IT43Da/?base_lang=es&response=html" target="_blank" class="menu-button es-button">Almuerzo</a>
        <a href="https://api.menutech.com/public/menus/dsl7bhLQ/?base_lang=es&response=html" target="_blank" class="menu-button es-button">Cena</a>
        <a href="https://api.menutech.com/public/menus/5DinYBkH/?base_lang=es&response=html" target="_blank" class="menu-button es-button">Bebidas</a>
        <a href="https://api.menutech.com/public/menus/YJPHaQym/?base_lang=es&response=html" target="_blank" class="menu-button es-button">Vinos</a>
      </div>
      <div class="menu-buttons-row">
        <a href="https://api.menutech.com/public/menus/H2IT43Da/?base_lang=it&response=html" target="_blank" class="menu-button it-button">Pranzo</a>
        <a href="https://api.menutech.com/public/menus/dsl7bhLQ/?base_lang=it&response=html" target="_blank" class="menu-button it-button">Cena</a>
        <a href="https://api.menutech.com/public/menus/5DinYBkH/?base_lang=it&response=html" target="_blank" class="menu-button it-button">Bevande</a>
        <a href="https://api.menutech.com/public/menus/YJPHaQym/?base_lang=it&response=html" target="_blank" class="menu-button it-button">Vinosto</a>
      </div>
      <div class="menu-buttons-row">
        <a href="https://api.menutech.com/public/menus/H2IT43Da/?base_lang=zh&response=html" target="_blank" class="menu-button zh-button">午餐</a>
        <a href="https://api.menutech.com/public/menus/dsl7bhLQ/?base_lang=zh&response=html" target="_blank" class="menu-button zh-button">晚餐</a>
        <a href="https://api.menutech.com/public/menus/5DinYBkH/?base_lang=zh&response=html" target="_blank" class="menu-button zh-button">饮料</a>
        <a href="https://api.menutech.com/public/menus/YJPHaQym/?base_lang=zh&response=html" target="_blank" class="menu-button zh-button">葡萄酒</a>
      </div>
      <!-- <div class="menu-buttons-row">
        <a href="./menus/menuEN.pdf" target="_blank" class="menu-button en-button">Menu</a>
        <a href="./menus/boissonsEN.pdf" target="_blank" class="menu-button en-button">Drinks</a>
        <a href="./menus/vinsEN.pdf" target="_blank" class="menu-button en-button">Wines</a>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenusPage',
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0..900;1..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gotham:wght@100..900&display=swap');

.menus-page {
  padding: 2rem;
  font-family: 'Gotham', sans-serif;
}

.display-1 {
  font-family: 'Fjalla One', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.menu-buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

.menu-buttons-row {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.menu-button {
  padding: 0.5rem 1rem;
  border: 1px solid #1a1a1a;
  background-color: #fbecd2;
  color: #1a1a1a;
  text-transform: uppercase;
  font-family: 'Fjalla One', sans-serif;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}

.menu-button:hover {
  background-color: #1a1a1a;
  color: #fbecd2;
}

.en-button {
  background-color: #e0e0e0;
}

.en-button:hover {
  background-color: #1a1a1a;
  color: #e0e0e0;
}

.es-button {
  background-color: #d69d8f;
}

.es-button:hover {
  background-color: #1a1a1a;
  color: #e0e0e0;
}

.it-button {
  background-color: #dbbb8e;
}

.it-button:hover {
  background-color: #1a1a1a;
  color: #e0e0e0;
}

.zh-button {
  background-color: #9ba699;
}

.zh-button:hover {
  background-color: #1a1a1a;
  color: #e0e0e0;
}
@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.subtitle {
  font-family: 'Merriweather Sans', sans-serif;
  font-weight: 300;
  font-style: italic;
}

/* Responsive Styles */
@media (max-width: 1210px) {

  .vertical-text {
    font-size: 2.5em;
  }

  .bigneon{
    font-size: 5em;
  }

  .nav-top {
    height: 3rem;
  }

  h1{
    font-size: 3em;
  }

  h2{
    font-size: 1.6em;
  }

  .nav-link {
    font-size: 0.9rem;
  }

}

@media (max-width: 610px) {
  .nav-side {
    width:3em;
  }

  .nav-top {
    flex-direction: column;
    height: auto;
  }

  .nav-link {
    padding: 0.5rem 0;
  }

  .separator {
    display: none;
  }

  .main-content {
    flex-direction: column;
  }

  .content-text {
    padding: rem;
  }

  .content{
    margin-left: 3em;
  }

  .bigneon {
    font-size: 5rem;
  }

  .lineleft{
    border: none;
  }
  .lineright{
    border: none;
  }
  .linetop{
    border: none;
  }

  .footer {
    flex-direction: column;
    text-align: center;
  }
  .motif{
    display:none;
  }
  .notebas {
    margin-bottom: 5rem;
  }
}
</style>
